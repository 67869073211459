import { isPlatformBrowser } from '@angular/common';
import { Component, AfterViewInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';

//declare var ymaps3: any;

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class Map implements AfterViewInit, OnDestroy {

  ngOnInit() { }
  ngOnDestroy(): void { }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId))
      if ('IntersectionObserver' in window) {
       // this.loadYandexMaps();
      }
  }


/*
  private loadYandexMaps(): void {
    ymaps3.ready.then(async () => {
      // HTML-элемент.
      const map = new ymaps3.YMap(document.getElementById('map'), {
        location: {
          center: [43.890607, 56.347000], // Координаты для центра карты
          zoom: 15
        }
      });
      const layer = new ymaps3.YMapDefaultSchemeLayer({ theme: 'dark' });
      map.addChild(layer);
      map.addChild(new ymaps3.YMapDefaultFeaturesLayer());
      const { YMapDefaultMarker } = await ymaps3.import('@yandex/ymaps3-markers@0.0.1');
      const marker = new YMapDefaultMarker({
        coordinates: [43.890607, 56.347000],
        title: 'РВД Сервис',
      }); map.addChild(marker);

    });
  }



*/

}