import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    private headerVisibleSubject = new Subject<boolean>();
    headerVisible$ = this.headerVisibleSubject.asObservable();

    setHeaderVisible(isVisible: boolean) {
        this.headerVisibleSubject.next(isVisible);
    }
}