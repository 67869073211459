import { Routes } from '@angular/router';

export const routes: Routes = [
    { path: "", loadComponent: () => import('./main/main.component').then(m => m.MainComponent)  },
    { path: "about", loadComponent: () => import('./about/about.component').then(m => m.AboutComponent) },
    { path: "repair", loadComponent: () => import('./repair/repair.component').then(m => m.RepairComponent) },
    { path: "warranty", loadComponent: () => import('./warranty/warranty.component').then(m => m.WarrantyComponent) },
    { path: "comments", loadComponent: () => import('./comments/comments.component').then(m => m.CommentsComponent) },
    { path: "delivery", loadComponent: () => import('./delivery/delivery.component').then(m => m.DeliveryComponent) },
    { path: 'catalog', loadChildren: () => import('./shop/shop-routing.module').then(m => m.CatalogModule) },
    {path: "**", redirectTo:""},
];
