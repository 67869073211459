import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, PLATFORM_ID, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PopupService } from '../../services/pupup.service';
import { trigger, style, transition, animate } from '@angular/animations';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'app-popup-stack',
  templateUrl: './popup-stack.component.html',
  standalone: true,
  imports: [AsyncPipe],
  styleUrls: ['./popup-stack.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('100ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PopupStackComponent implements AfterViewInit {
  @ViewChildren('modalContainer', { read: ViewContainerRef }) modalContainers!: QueryList<ViewContainerRef>;
  modals$: Observable<any[]>;

  // Предыдущие состояния модальных окон
  previousModals: any[] = [];
  modalsSubscription: any;
  modals: any[] = [];

  constructor(private popupService: PopupService, @Inject(PLATFORM_ID) private platformId: any, private cdRef: ChangeDetectorRef, private productService: ProductsService) {
    this.modals$ = this.popupService.popups$();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.modalsSubscription = this.modals$.subscribe(modals => {
        this.modals = modals; // Обновляем локальный массив
        this.cdRef.detectChanges(); // Принудительно обновляем привязки

        // Обновляем состояние при изменении модальных окон
        this.adjustBodyStyle(modals.length > 0);
        this.updateModals(modals);
      });

    }
  }
  ngAfterViewChecked(): void {
    // Проверяем, есть ли контейнеры и модальные окна
    if (this.modalContainers.length < this.modals.length) {

      return; // Если контейнеров меньше, выходим
    }

    this.updateModals(this.modals);
  }

  ngOnDestroy(): void {
    // Отписываемся от подписки, чтобы избежать утечек памяти
    if (this.modalsSubscription) {
      this.modalsSubscription.unsubscribe();
    }
  }
  subscr?: Subscription;


  updateModals(modals: any[]): void {

    modals.forEach((modal, index) => {
      const modalContainer = this.modalContainers.toArray()[index];
      if (modalContainer) {
        const previousModal = this.previousModals[index];

        if (!previousModal || previousModal.id !== modal.id || previousModal.data !== modal.data) {

          modalContainer.clear(); // Очистить контейнер перед созданием нового компонента
          const componentRef = modalContainer.createComponent(modal.component);
          Object.assign(componentRef.instance!, modal.data);
          //поправить
          if (modal.data.id) {

            this.subscr = this.productService.productIsLoading$.subscribe(data => {
              if (data.id == modal.data.id && data.status) {
                componentRef.location.nativeElement.classList.add("padding");
                this.subscr?.unsubscribe();
              }
            });

            this.previousModals = modals.map(modal => ({ ...modal }));

          } else {

            componentRef.location.nativeElement.classList.add("padding");
            this.previousModals = modals.map(modal => ({ ...modal }));
          }
        }

      }
    });


  }

  clearModals(): void {
    this.modalContainers.forEach(container => container.clear());
    this.previousModals = [];
  }

  close(modalId: string): void {
    this.popupService.closeById(modalId);
  }

  onBackdropClick(event: MouseEvent, id: string, uuid: string) {
    const target = event.target as HTMLElement;
    if (target.id === id) {
      this.close(uuid);
    }
  }


  adjustBodyStyle(hasModals: boolean): void {
    const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
    if (hasModals) {
      document.body.style.marginRight = `${scrollBarWidth}px`;
      document.body.style.overflow = 'hidden';
      document.getElementById("mobile-header")!.style.paddingRight = `${scrollBarWidth}px`;
      document.getElementById("header-desktop")!.style.paddingRight = `${scrollBarWidth}px`;
    } else {
      document.body.style.overflow = '';
      document.body.style.marginRight = '';
      document.getElementById("mobile-header")!.style.paddingRight = '';
      document.getElementById("header-desktop")!.style.paddingRight = '';
    }
  }
}
