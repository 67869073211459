import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomButton } from '../data/button.class';
import { v4 as uuidv4 } from 'uuid';
import { title } from 'node:process';


export class SearchObject {
    id:string;
    title: string;
    text?: string;
    button: CustomButton;
    constructor(data: { title: string, text?: string, button: CustomButton }) {
         this.id = uuidv4();
        this.title = data.title;
        this.text = data.text;
        this.button = data.button;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private apiUrl = 'api/search'; // Замените на ваш API URL

    constructor(private http: HttpClient) { }

    // Метод для поиска с диапазоном
    search(term: string, min: number, max: number): Observable<SearchObject[]> {
        // Параметры для запроса
        let params = new HttpParams()
            .set('q', term)
            .set('min', min)
            .set('max', max);

        // Отправляем запрос с параметрами
        // return of([
        //     new SearchObject({title:"элемент1,", text:"элемент2", button: new CustomButton({text:"", type:"popup", props:['item-form','ЧТО-то что-то']})}),
        //     new SearchObject({title:"элемент1,", text:"элемент2", button: new CustomButton({text:"", type:"popup", props:['item-form','ЧТО-то что-то']})}),
        //     new SearchObject({title:"элемент1,", text:"элемент2", button: new CustomButton({text:"", type:"popup", props:['item-form','ЧТО-то что-то']})}),
        //     new SearchObject({title:"элемент1,", text:"элемент2", button: new CustomButton({text:"", type:"popup", props:['item-form','ЧТО-то что-то']})}),
        //     new SearchObject({title:"элемент1,", text:"элемент2", button: new CustomButton({text:"", type:"popup", props:['item-form','ЧТО-то что-то']})}),
        //     new SearchObject({title:"элемент1,", text:"элемент2", button: new CustomButton({text:"", type:"popup", props:['item-form','ЧТО-то что-то']})}),
        //     new SearchObject({title:"элемент1,", text:"элемент2", button: new CustomButton({text:"", type:"popup", props:['item-form','ЧТО-то что-то']})}),
        // ])
        return  this.http.get<{ name: string, keys: string }[]>(this.apiUrl, { params }).pipe(
            map((response) => 
              response.map(e => new SearchObject({title:String(e.name), button: new CustomButton({text:"Заказать", type:"popup", props:['item-form',e.name]})}))));
    }
}
