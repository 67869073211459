<app-header></app-header>
<router-outlet />
<app-bottom></app-bottom>
<app-popup-stack></app-popup-stack>
<app-notifications></app-notifications>
<a class="navigator-button" aria-label="yandex maps navigator"  href="https://yandex.ru/maps/?rtext=~56.347000%2C43.890607" target="_blank" onclick="ym(95557011, 'reachGoal', 'route_map')"><img src="/assets/icons/yandex-nav.svg" alt=""></a>
<div class="bottom-buttons">
    <a class="bottom-buttons__button" href="tel:{{contacts.numberHref}}"><img src="assets/icons/dark/phone.svg" alt="phone"></a>
    <div class="bottom-buttons__separator"></div>
    <div class="bottom-buttons__button pointer" appCopyText="{{contacts.mail}}"><img src="assets/icons/dark/mail.svg" alt="mail"></div>
</div>