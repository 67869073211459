import { Component, Renderer2 } from '@angular/core';
import { Map } from "../map/map.component";
import { contacts } from '../../constants/constants';
import { RouterLink } from '@angular/router';
import { CopyTextDirective } from '../../directives/copy-text.directive';

@Component({
  selector: 'app-bottom',
  standalone: true,
  imports: [Map, RouterLink,CopyTextDirective],
  templateUrl: './bottom.component.html',
  styleUrl: './bottom.component.scss'
})
export class BottomComponent {
  contacts = contacts;
  constructor(private renderer: Renderer2) {}
  scrollToFooter() {
    const footer = this.renderer.selectRootElement('#footer-contacts-route', true);
    footer.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  scrollToMap() {
    const footer = this.renderer.selectRootElement('#footer-map-route', true);
    footer.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
